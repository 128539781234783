import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import {GatsbyImage} from 'gatsby-plugin-image'
import 'swiper/css/bundle';


SwiperCore.use([Autoplay, Navigation])

export default function GallerySlider({gallery_images}) {
    return (
        <Swiper
        spaceBetween={40}
        slidesPerView={1}
        autoplay={true, {delay: 3000}}
        loop={true}
        className="slider"
        >
            {
                gallery_images.map((item, index) => {
                    return(
                        <SwiperSlide key={item.title + '_' + index}>
                            <GatsbyImage className="image-wrapper" image={item.image?.localFile?.childImageSharp?.fluid} />
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    )
}
