import React, { useState, useEffect, useRef } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import AnimatedImage from "../components/global/AnimatedImage"
import parse from "html-react-parser"
import SocialShare from "../components/global/SocialShare"
import ArticleBuilder from "../components/blogArticle/ArticleBuilder"
import Arrow from "../assets/slider-arrow.inline.svg"
import GridCardSlider from "../components/global/GridCardSlider"
import SEO from "../components/seo"
import RellaxWrapper from "react-rellax-wrapper"
import { BlogArticleStyle } from "../styles/blog-article-styles"
import { GatsbyImage } from "gatsby-plugin-image"
import getListingSlug from "../utils/getListingSlug"

// We're using Gutenberg so we need the block styles

const PostHeader = styled.header`
  .bg_wrapper {
    height: calc(100% - ${props => props.heroHeight});
  }
`

const ArticleContent = styled.section``

const BlogPostTemplate = ({
  data: { previous, next, post, allAdverts, specificAdverts, fallbackAdverts },
  pageContext,
  data,
}) => {
  const { category, slug } = pageContext
  const heroRef = useRef(null)
  const [heroSize, setHeroSize] = useState(0)

  const featuredImage = {
    image:
      post.acf_customFeaturedImage?.featuredImage?.localFile?.childImageSharp
        ?.gatsbyImageData,
    alt: post.acf_customFeaturedImage?.featuredImage?.node?.alt || ``,
  }

  const wrapperStyles = {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "0",
    left: "0",
  }

  const pollinationPosts = []

  if (data.pollination.acf_internal_cross_pollinate.chooseListings) {
    const pollinators =
      data.pollination.acf_internal_cross_pollinate.chooseListings.filter(
        item => item.pickAListing !== null
      )

    pollinators.map((item, index) => {
      return item?.pickAListing?.__typename === "WpImported_listing"
        ? pollinationPosts.push({
            title: item?.pickAListing?.title,
            excerpt: item?.pickAListing?.acf_listings?.taglineSummary,
            link:
              "https://" +
              item?.pickAListing?.acf_imported_listings?.parentCity +
              ".independentlife.co.uk/independents/" +
              item.pickAListing.acf_imported_listings.slug,
            prefix: "independents",
            image: item?.pickAListing?.acf_listings?.image1,
            intOrExt: "external",
          })
        : item?.pickAListing?.__typename === "WpListing"
        ? pollinationPosts.push({
            title: item?.pickAListing?.title,
            excerpt: item?.pickAListing?.acf_listings?.taglineSummary,
            link: item?.pickAListing?.slug + "/",
            prefix: "independents",
            image: item?.pickAListing?.acf_listings?.image1,
            intOrExt: "internal",
          })
        : ""
    })
  }

  const latestPostsArray = []
  data.latest.nodes.map((item, index) => {
    return latestPostsArray.push({
      title: item?.title,
      excerpt: item?.excerpt,
      link: item?.slug + "/",
      prefix: item?.categories?.nodes[0].slug,
      acf_customFeaturedImage: item?.acf_customFeaturedImage,
    })
  })

  let adverts

  if (specificAdverts.edges.length > 0) {
    adverts = specificAdverts
  } else if (fallbackAdverts.edges.length > 0) {
    adverts = fallbackAdverts
  } else {
    adverts = allAdverts
  }

  const calcHeroSize = () => {
    if (heroRef.current) {
      setHeroSize(heroRef.current.offsetHeight)

      window.addEventListener("resize", calcHeroSize)
    }
  }

  useEffect(calcHeroSize)

  return (
    <div>
      <BlogArticleStyle />
      <SEO
        title={data.seo.seo.title}
        description={data.seo.seo.metaDesc}
        image={post.acf_customFeaturedImage.featuredImage.mediaItemUrl}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <PostHeader
          className="blog-article--postHeader"
          heroHeight={heroSize / 2 + "px"}
        >
          <nav className="blog-post-nav">
            <ul>
              <li>
                {previous && (
                  <Link to={slug + "/" + previous.slug} rel="prev">
                    <Arrow />
                  </Link>
                )}
              </li>

              <li>
                {next && (
                  <Link to={slug + "/" + next.slug} rel="next">
                    <Arrow />
                  </Link>
                )}
              </li>
            </ul>
          </nav>
          <div className="wrapper">
            <div className="catBreadcrumbs">
              Article - <Link to={slug}>{category}</Link>
            </div>
            <h1 className="heading--l">{parse(post.title)}</h1>

            <div className="author-date-share">
              <div className="author-date">
                By {post.author.node.name} | {post.date}
              </div>
              <div className="share">
                <SocialShare />
              </div>
            </div>
            {/* {console.log(
              post.acf_customFeaturedImage.featuredImage.localFile
                .childImageSharp.gatsbyImageData
            )} */}
            {/* if we have a featured image for this post let's display it */}
            {featuredImage?.image && (
              <div className="heroImage" ref={heroRef}>
                <GatsbyImage
                  image={
                    post.acf_customFeaturedImage.featuredImage.localFile
                      .childImageSharp.gatsbyImageData
                  }
                />
              </div>
            )}
          </div>
          <div className="bg_wrapper">
            <RellaxWrapper
              mobile={-1.25}
              tablet={-2}
              desktop={-5.25}
              breakpoints={[500, 1024, 1201]}
              style={wrapperStyles}
            >
              <div className="bg"></div>
            </RellaxWrapper>
          </div>
        </PostHeader>

        <ArticleContent className="article-content blog-article--articleContent">
          <div className="wrapper wrapper-flex">
            <aside className="sidebar">
              <div className="published-date">
                <span>Published: </span>
                <span>{post.date}</span>
              </div>
              <div className="author">
                <span>Written by: </span>
                <span>{post.author.node.name}</span>
              </div>
              {post.acf_additional_credits.additionalContributors ? (
                <div className="credits">
                  {post.acf_additional_credits.additionalContributors.map(
                    (contributor, index) => {
                      const role = contributor.role
                      const customRole = contributor.customRole
                      const user = contributor.user
                      return user?.firstName && user?.lastName ? (
                        <div
                          className="contributor"
                          key={user.firstName + index}
                        >
                          {role === "custom" ? (
                            <span className="role">{customRole}:</span>
                          ) : (
                            <span className="role">
                              {role.charAt(0).toUpperCase() + role.slice(1)} by:
                            </span>
                          )}
                          <span className="user">
                            {user.firstName} {user.lastName}
                          </span>
                        </div>
                      ) : (
                        ""
                      )
                    }
                  )}
                </div>
              ) : (
                ""
              )}

              {adverts.edges.map((advert, index) => {
                // const color = advert.node.acf_adverts.adColour;
                const title = advert.node.title
                const adPageLinkExternal =
                  advert.node.acf_adverts.adPageLinkExternal
                const adPageLink = advert?.node?.acf_adverts?.adPageLink?.slug
                const adPageListing = advert?.node?.acf_adverts?.adPageLink
                const advertImage =
                  advert?.node?.acf_adverts?.advertImage?.localFile
                    ?.childImageSharp?.gatsbyImageData
                const advertImageMobile =
                  advert?.node?.acf_adverts?.advertImageMobile?.localFile
                    ?.childImageSharp?.gatsbyImageData
                const internalOrExternalLink =
                  advert.node.acf_adverts.internalOrExternalLink
                return internalOrExternalLink === "external" ? (
                  <div className="business_tile" key={"ad" + title + index}>
                    <a
                      href={adPageLinkExternal}
                      className="trackClick"
                      target="_blank"
                      title={title}
                      rel="noreferrer noopenner"
                    >
                      <div className="desktop">
                        <GatsbyImage image={advertImageMobile} />
                      </div>
                      <div className="mobile">
                        <GatsbyImage image={advertImage} />
                      </div>
                    </a>
                  </div>
                ) : (
                  <div className="business_tile" key={"ad" + title + index}>
                    <Link
                      to={getListingSlug(adPageListing)}
                      className="trackClick"
                      title={title}
                    >
                      <div className="desktop">
                        <GatsbyImage image={advertImageMobile} />
                      </div>
                      <div className="mobile">
                        <GatsbyImage image={advertImage} />
                      </div>
                    </Link>
                  </div>
                )
              })}
            </aside>
            <div className="main-content">
              {!!post.content && (
                <div className="content-wrapper intro">
                  {parse(post.content)}
                </div>
              )}
              {post?.acf_article_twoimages?.twoImages?.imageLeft?.localFile
                ?.childImageSharp?.gatsbyImageData &&
              post?.acf_article_twoimages?.twoImages?.imageRight?.localFile
                ?.childImageSharp?.gatsbyImageData ? (
                <div className="content-wrapper  two-images">
                  <div className="images">
                    <AnimatedImage
                      imgSrc={
                        post?.acf_article_twoimages?.twoImages?.imageLeft
                          ?.localFile?.childImageSharp?.gatsbyImageData
                      }
                      offsetX={"1rem"}
                      offsetY={"1rem"}
                      color={"brandColor"}
                    />
                  </div>
                  <div className="images">
                    <AnimatedImage
                      imgSrc={
                        post?.acf_article_twoimages?.twoImages?.imageRight
                          ?.localFile?.childImageSharp?.gatsbyImageData
                      }
                      offsetX={"1rem"}
                      offsetY={"1rem"}
                      color={"brandColor"}
                    />
                  </div>
                </div>
              ) : (
                " "
              )}
              {post.acf_articleBuilder.articleBuilder ? (
                <ArticleBuilder data={post.acf_articleBuilder.articleBuilder} />
              ) : (
                ""
              )}

              <footer>
                <div className="footer-inner">
                  <div className="share">
                    <SocialShare />
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </ArticleContent>

        {
          // If has manaully specified Cross Pollination listings show them,
          // else show latest articles

          pollinationPosts.length > 0 ? (
            <GridCardSlider
              sectionTitle="Have you heard of..."
              linkPrefix={""}
              data={pollinationPosts}
              bgPosition="bottom"
              bgSize="0%"
              bgColor="white"
            />
          ) : (
            ""
          )
        }

        <GridCardSlider
          sectionTitle={`Latest ${category} Articles`}
          linkPrefix={""}
          data={latestPostsArray}
          bgPosition="bottom"
          bgSize="40%"
          bgColor="grey"
        />
      </article>
    </div>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $type: String
    $category: String
    $location: String
  ) {
    seo: wpPost(id: { eq: $id }) {
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            id
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }

    #================================
    # INTERNAL / CROSS POLLINATION
    #================================
    pollination: wpPost(id: { eq: $id }) {
      acf_internal_cross_pollinate {
        chooseListings {
          pickAListing {
            ... on WpListing {
              title
              slug
              acf_listings {
                image1 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                taglineSummary
              }
            }
            ... on WpImported_listing {
              title
              acf_listings {
                image1 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                taglineSummary
              }
              acf_imported_listings {
                slug
                parentCity
              }
            }
          }
        }
      }
    }

    #================================
    # LATEST ARTICLES SECTION
    #================================
    latest: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: $category } } } }
        id: { ne: $id }
      }
      limit: 10
    ) {
      nodes {
        title
        excerpt
        slug
        categories {
          nodes {
            slug
          }
        }
        acf_customFeaturedImage {
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allAdverts: allWpAdvert {
      edges {
        node {
          id
          title
          acf_adverts {
            adColour
            adPageLink {
              ... on WpListing {
                id
                slug
                title
                listingsTagLocation {
                  nodes {
                    slug
                    wpParent {
                      node {
                        slug
                      }
                    }
                  }
                }
              }
            }
            adPageLinkExternal
            advertImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            advertImageMobile {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            internalOrExternalLink
          }
        }
      }
    }

    fallbackAdverts: allWpAdvert(
      filter: {
        acf_adverts: {
          adCategory: {
            pickCategory: { elemMatch: { name: { eq: $category } } }
          }
        }
      }
    ) {
      edges {
        node {
          id
          title
          acf_adverts {
            adColour
            adPageLink {
              ... on WpListing {
                id
                slug
                title
                listingsTagLocation {
                  nodes {
                    slug
                    wpParent {
                      node {
                        slug
                      }
                    }
                  }
                }
              }
            }
            adPageLinkExternal
            advertImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            advertImageMobile {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            internalOrExternalLink
          }
        }
      }
    }

    specificAdverts: allWpAdvert(
      filter: {
        acf_adverts: {
          adCategory: {
            pickCategory: { elemMatch: { name: { eq: $category } } }
            pickType: { elemMatch: { name: { eq: $type } } }
            pickLocation: { elemMatch: { name: { eq: $location } } }
          }
        }
      }
    ) {
      edges {
        node {
          id
          title
          acf_adverts {
            adColour
            adPageLink {
              ... on WpListing {
                id
                slug
                title
                listingsTagLocation {
                  nodes {
                    slug
                    wpParent {
                      node {
                        slug
                      }
                    }
                  }
                }
              }
            }
            adPageLinkExternal
            advertImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            advertImageMobile {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            internalOrExternalLink
          }
        }
      }
    }

    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "D MMMM YYYY")
      author {
        node {
          name
        }
      }
      acf_additional_credits {
        additionalContributors {
          role
          customRole
          user {
            firstName
            lastName
          }
        }
      }
      acf_article_twoimages {
        twoImages {
          imageLeft {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imageLeftFocalPoint
          imageRight {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imageRightFocalPoint
        }
      }
      acf_customFeaturedImage {
        featuredImage {
          mediaItemUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      acf_articleBuilder {
        articleBuilder {
          ... on WpPost_AcfArticlebuilder_ArticleBuilder_Image {
            imageFocalPoint
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            fieldGroupName
          }
          ... on WpPost_AcfArticlebuilder_ArticleBuilder_Video {
            fieldGroupName
            video
          }
          ... on WpPost_AcfArticlebuilder_ArticleBuilder_Gallery {
            fieldGroupName
            gallery {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPost_AcfArticlebuilder_ArticleBuilder_Text {
            fieldGroupName
            text
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      slug
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      slug
      title
    }
  }
`
