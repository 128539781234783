import React from 'react'
import parse from 'html-react-parser'
import AnimatedImage from '../global/AnimatedImage'
import styled from 'styled-components';
import GallerySlider from './GallerySlider'

const ImageWrapper = styled.div`
position:relative;
padding-bottom:56%;
margin-bottom:4rem !important;
`

export default function ArticleBuilder({data}) {

    const fitVids = () => {
        if(typeof window !== 'undefined'){
            // List of Video Vendors embeds you want to support
            var players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]'];
              
            // Select videos
            var fitVids = document.querySelectorAll(players.join(","));
            // If there are videos on the page...
            if (fitVids.length) {
              // Loop through videos
              for (var i = 0; i < fitVids.length; i++) {
                // Get Video Information
                var fitVid = fitVids[i];
                var width = fitVid.getAttribute("width");
                var height = fitVid.getAttribute("height");
                var aspectRatio = height / width;
                var parentDiv = fitVid.parentNode;

                // console.log(aspectRatio);
          
                // Wrap it in a DIV
                var div = document.createElement("div");
                div.className = "fitVids-wrapper";
                div.style.paddingBottom = aspectRatio * 100 + "%";
                parentDiv.insertBefore(div, fitVid);
                fitVid.remove();
                div.appendChild(fitVid);
          
                // Clear height/width from fitVid
                fitVid.removeAttribute("height");
                fitVid.removeAttribute("width");
              }
            }
        }
    }

    fitVids();
    
    return (
        <div className="content-wrapper">
            {data.map((item, index) => {
                return(
                 item.fieldGroupName.includes("_AcfArticlebuilder_ArticleBuilder_Text") ?
                    
                        <div key={'text'+index} className="content text">{parse(item?.text)}</div>
                    
                : item.fieldGroupName.includes("_AcfArticlebuilder_ArticleBuilder_Video") ?
                    
                        <div key={'video'+index} className={item.video.includes('vimeo') ? `content video vimeo` : item.video.includes('youtube' ? 'content video youtube' : 'content video')}>
                            {parse(item.video)}
                            {/* <div className="video-wrapper">{parse(item.video)}</div> */}
                        </div>
                    
                    
                : item.fieldGroupName.includes("_AcfArticlebuilder_ArticleBuilder_Gallery") ?
                    
                        <div key={'gallery'+index} className="content gallery">
                            <GallerySlider gallery_images={item.gallery}/>
                        </div>
                    
                : item.fieldGroupName.includes("_AcfArticlebuilder_ArticleBuilder_Image") ?
                    
                        <ImageWrapper key={'image'+index} className="content image">                        
                            <AnimatedImage
                                imgSrc={item?.image?.localFile?.childImageSharp?.gatsbyImageData}
                                offsetX={'2rem'}
                                offsetY={'2rem'}
                                color={'brandColor'}
                            />
                        </ImageWrapper>
                : ""
                )
            })
            }
        </div>
    )
}
