import React from "react"
import parse from "html-react-parser"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Autoplay } from "swiper"
import Arrow from ".././../assets/slider-arrow.inline.svg"
import { FaLongArrowAltRight } from "react-icons/fa"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import FakeButton from "./FakeButton"
import { Link } from "gatsby"
import "swiper/css/bundle"

// const isClient = typeof window !== 'undefined';

// if(isClient){
//     let slideWidth = window.innerWidth / 100 * 28;
// }else{
//     let slideWidth = 200;
// }

const GridCardSliderSection = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 2rem 0 4rem 2rem;
  background: var(--white);

  @media only screen and (min-width: 1024px) {
    padding: 8rem 0 4rem 2rem;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    background-color: var(--${props => props.bgColor});
    width: 100%;
    height: ${props => props.bgSize};
    ${props => props.bgPosition}:0;
    z-index: 0;
  }

  @keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(10px);
      transform: translateX(10px);
    }
  }

  .swipe {
    color: var(--brandColor);
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 2rem);
    position: relative;
    z-index: 10;
    animation: slide-right 0.75s cubic-bezier(0.895, 0.03, 0.685, 0.22) infinite
      alternate both;

    @media only screen and (min-width: 1024px) {
      display: none;
    }

    svg {
      margin-left: 0.5rem;
      margin-top: -1px;
    }
  }

  .section-title {
    margin-bottom: 2rem;
    margin-left: -1rem;
    z-index: 10;
    position: relative;
    color: var(--${props => props.titleColor});
    pointer-events: none;
  }

  .arrows {
    position: absolute;
    right: 2rem;
    top: 0;
    z-index: 1;
    display: none;

    @media only screen and (min-width: 1024px) {
      display: block;
    }

    .arrow {
      cursor: pointer;
      transition: 0.5s all ease-in-out;

      &.inactive {
        cursor: default;
        pointer-events: none;
        opacity: 0.3;
      }

      &.white {
        path {
          fill: var(--white);
        }
      }
    }

    .prev {
      transform: rotate(180deg);
      margin-right: 2rem;
    }
  }

  .slider {
    width: calc(100% + 2rem);
    margin-left: -2rem;
    padding-left: 2rem;
    position: relative;
    overflow: hidden;
    display: flex;
    list-style: none;

    .slide {
      width: calc(100% - 2rem);
      margin-right: 1rem;
      font-family: var(--font-reg);
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 2rem;
      padding-bottom: 180%;

      @media only screen and (min-width: 370px) {
        padding-bottom: 140%;
      }

      @media only screen and (min-width: 450px) {
        padding-bottom: 100%;
      }
      @media only screen and (min-width: 550px) {
        padding-bottom: 70%;
      }

      @media only screen and (min-width: 768px) {
        padding-bottom: 120%;
      }

      @media only screen and (min-width: 1024px) {
        width: 100%;
        padding-bottom: 0;
        height: 28vw;
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--brandColor);
        z-index: -1;
        transition: 0.5s all ease-in-out;
        transform: translate(0, 0);
      }

      &.external.leeds:before {
        background: var(--leeds);
      }
      &.external.york:before {
        background: var(--york);
      }
      &.external.manchester:before {
        background: var(--manchester);
      }

      .article_inner {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 10;

        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.6);
          z-index: -1;
          mix-blend-mode: darken;
          transition: 0.5s all ease-in-out;
        }

        a {
          text-decoration: none;
          color: var(--white);
          display: flex;
          position: relative;
          flex-direction: column;
          /* justify-content:space-between; */
          height: 100%;
          width: 100%;
          padding: 1.5rem;
          flex-wrap: wrap;
        }

        .fakeButton {
          position: absolute;
          bottom: 34px;
        }

        .article-title {
          font-family: var(--font-bold);
          font-size: 1.6rem;
          margin-bottom: 2rem;
          width: 100%;
        }

        .excerpt {
          width: 100%;
          font-size: 0.9rem;
          line-height: 1.2rem;

          * {
            line-height: 1.2rem;
          }
        }

        .image-wrapper {
          width: 100%;
          height: 100%;
          position: absolute !important;
          top: 0;
          left: 0;
          z-index: -2;
          transition: 0.5s all ease-in-out;
        }
      }

      &:hover {
        &:before {
          transform: translate(1rem, 1rem);
        }

        .article_inner:before {
          background: rgba(0, 0, 0, 0.8);
        }

        .fakeButton div {
          &:after {
            transform: translateX(0%);
          }
          color: var(--black);
          svg path {
            fill: var(--black);
          }
        }

        .image-wrapper {
          transform: scale(1.2);
        }
      }
    }

    .swiper-slide-active {
      .slide {
        &:before {
          transform: translate(1rem, 1rem);
        }

        @media only screen and (min-width: 1024px) {
          &:before {
            transform: translate(0, 0) !important;
          }

          &:hover:before {
            transform: translate(1rem, 1rem) !important;
          }
        }
      }
    }
  }
`
SwiperCore.use([Autoplay, Navigation])

export default function GridCardSlider({
  sectionTitle,
  data,
  areaBase,
  linkPrefix,
  bgPosition,
  bgSize,
  bgColor,
  titleColor,
  arrowColor,
  isInsta,
}) {
  const nodes = data

  // console.log(nodes)

  return (
    <GridCardSliderSection
      bgPosition={bgPosition ? bgPosition : "0"}
      bgSize={bgSize ? bgSize : "0"}
      bgColor={bgColor ? bgColor : "none"}
      titleColor={titleColor}
    >
      <div
        className={
          "slider-wrap--" +
          sectionTitle
            .toLowerCase()
            .replace(/ /g, "")
            .replace(/[^A-Z0-9]/gi, "_")
        }
      >
        <div className="wrapper">
          <h2 className="section-title heading--l">{sectionTitle}</h2>
          <div className="arrows">
            <Arrow
              className={
                arrowColor === "white" ? "prev arrow white" : "prev arrow"
              }
            />
            <Arrow
              className={
                arrowColor === "white" ? "next arrow white" : "next arrow"
              }
            />
          </div>
        </div>
        <Swiper
          loopFillGroupWithBlank={true}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 40,
            },
            1600: {
              slidesPerView: 3.5,
              spaceBetween: 40,
            },
          }}
          autoplay={
            (true,
            {
              delay: 4500,
            })
          }
          navigation={{
            nextEl:
              ".slider-wrap--" +
              sectionTitle
                .toLowerCase()
                .replace(/ /g, "")
                .replace(/[^A-Z0-9]/gi, "_") +
              " .arrow.next",
            prevEl:
              ".slider-wrap--" +
              sectionTitle
                .toLowerCase()
                .replace(/ /g, "")
                .replace(/[^A-Z0-9]/gi, "_") +
              " .arrow.prev",
          }}
          loop={true}
          // width={slideWidth}
          className="slider"
        >
          {nodes.map((item, index) => {
            return (
              <SwiperSlide key={item.title + "_" + index}>
                {item.intOrExt === "external" ? (
                  <article className={`slide external ${item.city}`}>
                    <div className="article_inner">
                      <a href={item.link} rel="norefferer noopener">
                        <h3 className="article-title">{item.title}</h3>
                        <div className="excerpt">
                          {parse(item?.excerpt?.substring(0, 310) + "....")}
                        </div>
                        <FakeButton
                          color="white"
                          text="Read now"
                          withHover={false}
                        />
                        {item?.image?.localFile?.childImageSharp ? (
                          <GatsbyImage
                            className="image-wrapper"
                            image={
                              item?.image?.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                          />
                        ) : (
                          <GatsbyImage
                            className="image-wrapper"
                            image={
                              item?.acf_customFeaturedImage?.featuredImage
                                ?.localFile?.childImageSharp?.gatsbyImageData
                            }
                          />
                        )}
                      </a>
                    </div>
                  </article>
                ) : (
                  <article className="slide">
                    {/* {console.log({ prefix: item.prefix, link: item.link })} */}
                    <div className="article_inner">
                      {areaBase ? (
                        <Link to={`/areas/${areaBase}/${item.link}`}>
                          <h3 className="article-title">{item?.title}</h3>
                          <div className="excerpt">
                            {parse(item?.excerpt?.substring(0, 310) + "....")}
                          </div>
                          <FakeButton
                            color="white"
                            text="Read now"
                            withHover={false}
                          />
                          {item?.image?.localFile?.childImageSharp &&
                          isInsta !== true ? (
                            <GatsbyImage
                              className="image-wrapper"
                              image={
                                item?.image?.localFile?.childImageSharp
                                  ?.gatsbyImageData
                              }
                            />
                          ) : (
                            <GatsbyImage
                              className="image-wrapper"
                              image={
                                item?.acf_customFeaturedImage?.featuredImage
                                  ?.localFile?.childImageSharp?.gatsbyImageData
                              }
                            />
                          )}
                          {isInsta === true ? (
                            <GatsbyImage
                              className="image-wrapper"
                              image={
                                item?.acf_customFeaturedImage?.localFile
                                  ?.childImageSharp?.gatsbyImageData
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : (
                        <Link
                          to={
                            item.prefix
                              ? "/" + item.prefix + "/" + item.link
                              : item.link
                          }
                        >
                          <h3 className="article-title">{item?.title}</h3>
                          <div className="excerpt">
                            {parse(item?.excerpt?.substring(0, 310) + "....")}
                          </div>
                          <FakeButton
                            color="white"
                            text="Read now"
                            withHover={false}
                          />
                          {item?.image?.localFile?.childImageSharp &&
                          isInsta !== true ? (
                            <GatsbyImage
                              className="image-wrapper"
                              image={
                                item?.image?.localFile?.childImageSharp
                                  ?.gatsbyImageData
                              }
                            />
                          ) : (
                            <GatsbyImage
                              className="image-wrapper"
                              image={
                                item?.acf_customFeaturedImage?.featuredImage
                                  ?.localFile?.childImageSharp?.gatsbyImageData
                              }
                            />
                          )}
                          {isInsta === true ? (
                            <GatsbyImage
                              className="image-wrapper"
                              image={
                                item?.acf_customFeaturedImage?.localFile
                                  ?.childImageSharp?.gatsbyImageData
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Link>
                      )}
                    </div>
                  </article>
                )}
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className="swipe">
          Swipe to scroll <FaLongArrowAltRight />
        </div>
      </div>
    </GridCardSliderSection>
  )
}
