import React from 'react'
import styled from 'styled-components'
import {AiFillFacebook} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'

const ShareWrapper = styled.div`
  font-family:var(--font-bold);
  display:flex;
  align-items:center;

    span{
    margin-right:1rem;
    }

`
const ShareItem = styled.div`
font-size:1.25rem;

    button{
    background:none;
    border:none;
    padding:0;
    margin-right:1rem;
    cursor: pointer;

        &:focus{
        outline:none;
        }

        svg{
        pointer-events:none;
        transition:0.5s all ease-in-out;
        }

        &:hover{
            svg{
            opacity:0.8;
            }
        }
    }

`

export default function SocialShare() {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    
    const share = (e) => {
        const shareUrl = e.target.dataset.url;
        window.open(shareUrl, null, "height=300,width=550,resizable=1");
    }
    return (
        <ShareWrapper className="post__share cf">    
            <span>Share</span>
            <ShareItem className="post__share__channel">
                <button className="post__share__link facebook" data-share="facebook"  data-url={`http://www.facebook.com/sharer.php?u=${url}`} onClick={share}>
                    <AiFillFacebook  title="Share on Facebook"/>
                </button>
            </ShareItem>

            <ShareItem className="post__share__channel">
                <button  className="post__share__link twitter"  data-url={`https://twitter.com/share?url=${url}`} onClick={share}>
                    <AiOutlineTwitter  title="Share on Twitter" />
                </button>
            </ShareItem>
        </ShareWrapper>
    )
}


