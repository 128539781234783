import { createGlobalStyle } from 'styled-components'


export const BlogArticleStyle = createGlobalStyle`
    .blog-article--postHeader{
    position:relative;
    padding:4rem 0 0 0;

        .bg_wrapper{
            top:0;
            left:0;
            z-index:-1;
            position:absolute;
            width:100%;
            height:calc(100% - ${props => props.heroHeight});
            
            .bg{
            position:absolute;
            width:100%;
            height:100%;
            background:var(--brandColor);
            top:0;
            left:0;
            }
        }


        .blog-post-nav{
        position: absolute;
        top:4rem;
        right:4rem;
        z-index:20;
        display:none;

        @media only screen and (min-width: 1024px) {
        display:block;
        }

            ul{
            padding:0;
            list-style:none;

            li{
            display:inline-block;

                a[rel="prev"] {
                margin-right:2rem;
                
                svg{
                transform:scale(-1)
                }
                }

                a{
                transition:0.5s all ease-in-out;

                &:hover{
                opacity:0.4;
                }
                }

            }
            }

        }

        .author-date-share{
            display:flex;
            flex-wrap:wrap;

            @media only screen and (min-width: 1024px) {
                flex-wrap:nowrap;
            }
        }

        .share{
        font-size:0.9rem;
        display:flex;
        align-items:center;
        margin-bottom:2rem;
        position: relative;
        width:100%;
        

            @media only screen and (min-width: 1024px) {
                width:auto;
                margin-left:4rem;
            }

        }


        .author-date,
        .catBreadcrumbs{
        font-size:0.9rem;
        display:flex;
        align-items:center;
        margin-bottom:2rem;
        position: relative;

            a{
            font-family:var(--font-bold);
            text-decoration:none;
            color:var(--black);
            transition:0.5s all ease-in-out;
            position: relative;
            overflow:hidden;
            display:inline-block;
            margin-left:5px;
            
            &:after{
            width:100%;
            height:2px;
            background:var(--black);
            content:"";
            position:absolute;
            bottom:0;
            left:0;
            transform:translateX(-100%);
            transition:0.5s all ease-in-out;
            }

            &:hover{
                &:after{
                transform:translateX(0%);        
                }
            }

            }
        }
        
        .heading--l{
        line-height:2.75rem;
        max-width:680px;
        }

        .heroImage{
        width:100%;
        position:relative;
        padding-bottom:70%;

        @media only screen and (min-width: 500px) {
            padding-bottom:60%;
        }
       
        @media only screen and (min-width: 700px) {
            padding-bottom:50%;
        }
        
        @media only screen and (min-width: 850px) {
            padding-bottom:42%;
        }

        @media only screen and (min-width: 1024px) {
            padding-bottom:36%;
        }
            .gatsby-image-wrapper{
            width:100% !important;
            height:100% !important;
            position:absolute !important;
            top:0 !important;
            left:0 !important;
            }

        }
    }

    .blog-article--articleContent{
    padding:4rem 0;

        .wrapper-flex{
            display:flex;
            flex-wrap:wrap;
            justify-content:flex-end;
        }


        footer{
        width:100%;
        position:relative;
        display:block;
        margin-top:2rem; 

            .footer-inner{
            padding-top:2rem;
            width:100%;
            border-top:1px solid var(--black);
            position:relative;  
            }
        }

        .sidebar{
        order:2;
        width:100%;
            
            @media only screen and (min-width: 1024px) {
            width:300px;
            height:100%;
            margin-right:4rem;
            order:1;
            }
            
            .contributor,
            .author,
            .published-date{
            width:100%;
            margin-bottom:2rem;

            span{
            width:100%;
            display:block;

                &:nth-of-type(1){
                margin-bottom:0.5rem;
                }
                &:nth-of-type(2){
                font-family:var(--font-bold);
                }

            }

            }

            .business_tile{
            margin-bottom:1rem;

            a{
            overflow:hidden;
            

                .gatsby-image-wrapper img{
                transition:0.5s all ease-in-out !important;
                }

                &:hover{
                .gatsby-image-wrapper img{
                transform:scale(1.1)
                }
                }

            }

            .desktop{
            display:none;

                @media only screen and (min-width: 1024px) {
                display:block;
                }

            }
            .mobile{
            display:block;

                @media only screen and (min-width: 1024px) {
                display:none;
                }

            }

            }
        
        }

        .two-images{
        width:100%;
        position:relative;
        margin-bottom:4rem;

            .images{
            position:relative;  
            width:100%;
            
            @media only screen and (min-width: 750px) {
            width:calc(50% - 1rem);
            }

            &:first-of-type{
            margin-bottom:2rem;

            @media only screen and (min-width: 750px) {
            margin-bottom:0;
            margin-right:2rem;
            }

                .image-wrapper.active:after{
                right:1rem !important;
                }

            }

            img{
            width:100%;
            height:100%;
            position: absolute;
            }

            }

        }

        .main-content{
        width:100%;
        order:1;

            @media only screen and (min-width: 1024px) {
            width:calc(100% - 300px - 4rem);
            order:2;
            }

        }

        .content-wrapper{
        width:100%;
        position:relative;
        display:flex;
        flex-wrap:wrap;


            &.intro{
            margin-bottom:2rem;
            }
            
            .content.image{
            padding-bottom:0;
            }

            > :last-child{
            margin-bottom:0 !important;
            }

            .gatsby-image-wrapper{
            max-height:50vh;
            }
            


            .content{
            width:100%;
            margin-bottom:2rem;
            position:relative;

            > :last-child{
                margin-bottom:0 !important;
            }

            }

            .video{
            margin-bottom:4rem !important;
            position:relative;

                &:after{
                width:100%;
                height:100%;
                position:absolute;
                top:0;
                left:0;
                content:"";
                background:var(--brandColor);
                transform:translate(1rem, 1rem);
                z-index:1;
                }
                        
                .fitVids-wrapper {
                position: relative;
                /* padding-bottom: 56.25%; */
                height: 0;
                z-index:100;
                }
                .fitVids-wrapper iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                }
            }

            .gallery{
            margin-bottom:4rem;
            position:relative;

            &:after{
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            content:"";
            background:var(--brandColor);
            transform:translate(2rem, 2rem);
            }
            
            .slider{
            width:100%;
                

                .swiper-slide{
                width:100%;
                padding-bottom:60%;

                .gatsby-image-wrapper{
                position: absolute !important;
                top:0 !important;
                left:0 !important;
                width:100% !important;
                height:100% !important;
                object-fit:cover;
                }

                }

            }
            }

            *{
            width:100%;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6{
            margin-bottom:1rem;
            width:100%;
            line-height:2.75rem;
            }
            
            a{
            color:inherit;
            text-decoration:none;
            }

            p{
            margin-bottom:1rem;
            width:100%;
            line-height:1.5rem;
            }
        

        }
    }
`
